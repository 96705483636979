// This is just an example,
// so you can safely delete all default props below

export default {
  failed: 'Action failed',
  success: 'Action was successful',
  index: {
    operation: 'Operation Docs',
    app_store: 'Commercial License',
    signin: 'Access address:',
    app_title: 'APP Title',
    slogan: 'Slogan',
    server: 'Request Baseurl',
    index_title: 'Warehouse Management System',
    webtitle: 'ViseshPro--Visesh Global Warehouse Management System',
    home: 'Home',
    title: 'ViseshPro',
    title_tip: 'ViseshPro Home',
    hide_menu: 'Hide Menu',
    show_menu: 'Show Menu',
    api: 'API DOCS',
    translate: 'Choose Language',
    unread: 'Unread Message',
    qr: 'QR Scanner',
    good_details: 'Good Details',
    bin_details: 'Bin Details',
    good_code: 'Good Barcode',
    bin_code: 'Bin Barcode',
    get_detail: 'Get Detail',
    login: 'Login',
    register: 'Register',
    login_tip: 'Enter Your OPENID & Login Name',
    register_tip: 'Register An Admin',
    logout: 'Logout',
    user_login: 'User Login',
    admin_login: 'Admin Login',
    return_to_login: 'Return To Login Page',
    user_center: 'User Center',
    change_user: 'Change User',
    view_my_openid: 'View My OPENID',
    your_openid: 'Your OPENID',
    contact_list: 'Recent Contact',
    chat_more: 'Load More',
    chat_no_more: 'No More Message',
    chat_send: 'Send',
    previous: 'Previous',
    next: 'Next',
    admin_name: 'Admin',
    password: 'Password',
    confirm_password: 'Confirm Password',
    staff_name: 'User Name',
    cancel: 'Cancel',
    close: 'Close',
    submit: 'Submit',
    download: 'Download',
    updatetitle: 'Update Ready',
    updatedesc: 'Version Can Update Now',
    update: 'Update Now',
    chart: ' Chart',
    current_user: 'Current User'
  },
  Settings: {
    index: 'Settings',
    server: 'Server',
    equipment: 'Equipment Support'
  },
  menuItem: {
    dashboard: 'Dashboard',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stock: 'Inventory',
    finance: 'Finance',
    goods: 'GoodsList',
    baseinfo: 'Base Info',
    warehouse: 'Warehouse',
    staff: 'Staff',
    driver: 'Driver',
    customerdn: 'Customer DN',
    supplierasn: 'Supplier ASN',
    uploadcenter: 'Upload Center',
    downloadcenter: 'Report Center',
    integrationhub: 'Integration Hub',
    customconfig: 'Custom Configuration',
    cloudwarehouse: 'Warehouse Interconnection'
  },
  contact: 'Contact',
  sendmessage: 'Send A Message',
  send: 'Send',
  nomoremessage: 'No More Message',
  loadmore: 'Load More',
  new: 'new',
  newtip: 'Add New Data',
  refresh: 'Refresh',
  refreshtip: 'Refresh All Data',
  edit: 'Edit This Data',
  confirmedit: 'Confirm Edit Data',
  canceledit: 'Cancel Edit Data',
  delete: 'Delete This Data',
  deletetip: 'This is an irreversible process.',
  confirmdelete: 'Confirm Delete Data',
  canceldelete: 'Cancel Delete Data',
  download: 'Download',
  downloadtip: 'Download All Data',
  frombin: 'From Bin',
  movetobin: 'Move to Bin',
  putaway: 'PutAway',
  cyclecount: 'Cycle Count',
  cyclecountrecorder: 'Count Recorder',
  search: 'Search Word',
  creater: 'Creater',
  createtime: 'Create Time',
  updatetime: 'Update Time',
  action: 'Action',
  previous: 'Previous',
  next: 'Next',
  no_data: 'No More Data',
  submit: 'Submit',
  cancel: 'Cancel',
  estimate: 'Estimate Freight',
  downloadasnlist: 'Download List',
  downloadasndetail: 'Download Detail',
  downloadasnlisttip: 'Download All ASN List',
  downloadasndetailtip: 'Download All ASN Detail',
  printthisasn: 'Print this ASN',
  confirmdelivery: 'Confirm Delivery',
  finishloading: 'Finish Loading',
  confirmsorted: 'Confirm Sorted',
  downloaddnlist: 'Download List',
  downloaddndetail: 'Download Detail',
  downloaddnlisttip: 'Download All DN List',
  downloaddndetailtip: 'Download All DN Detail',
  printthisorder: 'Print this Order',
  release: 'Order Release',
  releaseallorder: 'Release All Order',
  releaseorder: 'Release Order',
  print: 'Print Picking List',
  printthisdn: 'Print this DN',
  confirmorder: 'Confirm Order',
  confirmpicked: 'Confirm Picked',
  dispatch: 'Dispatch & Shipping',
  deletebackorder: 'Delete Back Order',
  confirminventoryresults: 'Confirm Inventory Results',
  qty: 'Quantity',
  not_available: 'Not Available',
  baseinfo: {
    company_info: 'Company Info',
    supplier: 'Supplier',
    customer: 'Customer',
    view_company: {
      company_name: 'Company Name',
      company_city: 'Company City',
      company_address: 'Company Address',
      company_contact: 'Company Contact',
      company_manager: 'Company Manager',
      error1: 'Please Enter The Company Name',
      error2: 'Please Enter The Company City',
      error3: 'Please Enter The Company Address',
      error4: 'Please Enter The Company Contact',
      error5: 'Please Enter The Company Manager'
    },
    view_supplier: {
      supplier_name: 'Supplier Name',
      supplier_city: 'Supplier City',
      supplier_address: 'Supplier Address',
      supplier_contact: 'Supplier Contact',
      supplier_manager: 'Supplier Manager',
      supplier_level: 'Supplier Level',
      error1: 'Please Enter the Supplier Name',
      error2: 'Please Enter the Supplier City',
      error3: 'Please Enter the Supplier Address',
      error4: 'Please Enter the Supplier Contact',
      error5: 'Please Enter the Supplier Manager',
      error6: 'Please Enter the Supplier Level'
    },
    view_sender: {
      sender_name: 'Sender Name',
      sender_city: 'Sender City',
      sender_address: 'Sender Address',
      sender_contact: 'Sender Contact',
      sender_manager: 'Sender Manager',
      sender_level: 'Sender Level',
      error1: 'Please Enter the Sender Name',
      error2: 'Please Enter the Sender City',
      error3: 'Please Enter the Sender Address',
      error4: 'Please Enter the Sender Contact',
      error5: 'Please Enter the Sender Manager',
      error6: 'Please Enter the Sender Level'
    },
    view_customer: {
      customer_name: 'Customer Name',
      customer_city: 'Customer City',
      customer_address: 'Customer Address',
      customer_contact: 'Customer Contact',
      customer_manager: 'Customer Manager',
      customer_level: 'Customer Level',
      error1: 'Please Enter the Customer Name',
      error2: 'Please Enter the Customer City',
      error3: 'Please Enter the Customer Address',
      error4: 'Please Enter the Customer Contact',
      error5: 'Please Enter the Customer Manager',
      error6: 'Please Enter the Customer Level'
    }
  },
  dashboards: {
    outbound_statements: 'Outbound',
    inbound_statements: 'Inbound',
    inbound_and_outbound_statements: 'Inbound And Outbound',
    inventory_management: 'Inventory Management',
    total_sales: 'Total Sales',
    sales_volume_ranking: 'Sales Volume Ranking',
    sales_volumes_ranking: 'Sales Volumes Ranking',
    total_receipts: 'Total Receipts',
    receiving_quantity_ranking: 'Receiving Quantity Ranking',
    Receiving_amount_ranking: 'Receiving Amount Ranking',
    view_tradelist: {
      mode_code: 'Mode Of Doing Business',
      supplier_or_customer: 'Supplier/Customer',
      bin_name: 'Location Name',
      goods_code: 'Goods Code',
      goods_qty: 'Update Quantity',
      qty_after_update: 'Updated Bin Stock',
      total_rem_qty: 'Available Stock',
      creater: 'Creater',
      update_time: 'Update Time',
      create_time: 'Create Time',
      inbound: 'Inbound',
      outbound: 'Outbound'
    }
  },
  finance: {
    capital: 'Capital',
    freight: 'Freight',
    view_capital: {
      capital_name: 'Cpaital Name',
      capital_qty: 'Capital Qty',
      capital_cost: 'Capital Cost',
      error1: 'Please Enter the Capital Name',
      error2: 'Capital Qty width must greater than 0',
      error3: 'Capital Cost depth must greater than 0'
    },
    view_freight: {
      transportation_supplier: 'Transportation Supplier',
      send_city: 'Send City',
      receiver_city: 'Receiver City',
      weight_fee: 'Weight Fee',
      volume_fee: 'Volume Fee',
      min_payment: 'Min Payment',
      error1: 'Please Enter the Transportation Supplier',
      error2: 'Please Enter the Send City',
      error3: 'Please Enter the Receiver City',
      error4: 'Weight Fee must greater than 0',
      error5: 'Volume Fee must greater than 0',
      error6: 'Min Payment must greater than 0'
    }
  },
  driver: {
    driver: 'Driver',
    dispatchlist: 'Dispatch List',
    error1: 'Please Enter the Driver Name',
    error2: 'Please Enter the License Plate',
    error3: 'Please Enter The Contact',
    view_driver: {
      driver_name: 'Driver Name',
      license_plate: 'License Plate',
      contact: 'Contact'
    },
    view_dispatch: {
      driver_name: 'Driver Name',
      dn_code: 'DN Code',
      contact: 'Contact'
    }
  },
  upload_center: {
    initializeupload: 'Initialize upload',
    uploadfiles: 'Upload',
    upload: 'Upload',
    uploadcustomerfile: 'Upload Customerfile',
    uploadgoodslistfile: 'Upload GoodslistFile',
    uploadsupplierfile: 'Upload SupplierFile',
    uploadasnlistfile: 'Upload ASNListFile',
    uploaddnlistfile: 'Upload DNListFile',
    uploadorderlistfile: 'Upload OrderFile',
    downloadgoodstemplate: 'Goods Example',
    downloadcustomertemplate: 'Customer Example',
    downloadsuppliertemplate: 'Supplier Example',
    downloadasntemplate: 'Asn Example',
    downloaddntemplate: 'Dn Example',
    downloadordertemplate: 'Order Example',
    addupload: 'Add Upload'
  },
  download_center: {
    createTime: 'Create Time',
    reset: 'Reset',
    start: 'Start',
    end: 'End'
  },
  community_mall: {
    communitymall: 'Community Mall'
  },
  goods: {
    goods_list: 'Goods List',
    unit: 'Unit',
    class: 'Class',
    color: 'Color',
    brand: 'Brand',
    shape: 'Shape',
    specs: 'Specs',
    origin: 'Origin',
    view_goodslist: {
      goods_code: 'Goods Code',
      goods_desc: 'Goods Desc',
      goods_name: 'Goods Name',
      goods_supplier: 'Goods Supplier',
      goods_weight: 'Goods Weight(Kilogram)',
      goods_w: 'Goods Width(Metre)',
      goods_d: 'Goods Depth(Metre)',
      goods_h: 'Goods Height(Metre)',
      unit_volume: 'Unit Volume(Cubic Metres)',
      goods_unit: 'Goods Unit',
      goods_class: 'Goods Class',
      goods_brand: 'Goods Brand',
      goods_color: 'Goods Color',
      goods_shape: 'Goods Shape',
      goods_specs: 'Goods Specs',
      goods_origin: 'Goods Origin',
      goods_cost: 'Goods Cost',
      goods_price: 'Goods Price',
      print_goods_label: 'Print Goods Label',
      error1: 'Please Enter the Goods Code',
      error2: 'Please Enter the Goods Description',
      error3: 'Please Enter the Supplier',
      error4: 'Goods Weight Must be Greater Than 0',
      error5: 'Goods Width Must be Greater Than 0',
      error6: 'Goods Depth Must be Greater Than 0',
      error7: 'Goods Height Must be Greater Than 0',
      error8: 'Please Enter the Goods Cost',
      error9: 'Please Enter the Goods Price',
      error10: 'Goods Cost Must be Greater Than 0',
      error11: 'Goods Price Must be Greater Than 0'
    },
    view_unit: {
      goods_unit: 'Goods Unit',
      error1: 'Please Enter Goods Unit'
    },
    view_class: {
      goods_class: 'Goods Class',
      error1: 'Please Enter Goods Class'
    },
    view_color: {
      goods_color: 'Goods Color',
      error1: 'Please Enter Goods Color'
    },
    view_brand: {
      goods_brand: 'Goods Brand',
      error1: 'Please Enter Goods Brand'
    },
    view_shape: {
      goods_shape: 'Goods Shape',
      error1: 'Please Enter Goods Shape'
    },
    view_specs: {
      goods_specs: 'Goods Specs',
      error1: 'Please Enter Goods Specs'
    },
    view_origin: {
      goods_origin: 'Goods Origin',
      error1: 'Please Enter Goods Origin'
    }
  },
  inbound: {
    asn: 'ASN',
    predeliverystock: 'Pre Delivery',
    preloadstock: 'Pre Load',
    presortstock: 'Pre Sort',
    sortstock: 'Put Away',
    shortage: 'Shortage',
    more: 'More QTY',
    asnfinish: 'Receiving List',
    asndone: 'Finish Receiving',
    view_sortstock: {
      error1: 'Please Enter The Quantity Must Be Greater Than 0'
    },
    view_asn: {
      asn_code: 'ASN Code',
      reference_number: 'Reference Number',
      asn_status: 'ASN Status',
      goods_qty: 'ASN QTY',
      goods_actual_qty: 'Actual Arrive Qty',
      goods_shortage_qty: 'Arrive Shortage Qty',
      goods_more_qty: 'Arrive More Qty',
      goods_damage_qty: 'Arrive Damage Qty',
      presortstock: 'Pre Sort Qty',
      sorted_qty: 'Sorted Qty',
      total_weight: 'Total Weight(Kilogram)',
      total_volume: 'Total Volume(Cubic Metres)',
      comments: 'Comments'
    }
  },
  outbound: {
    order: 'Order',
    dn: 'Confirmed',
    freshorder: 'Pre Order',
    neworder: 'Released',
    backorder: 'Back Order',
    pickstock: 'Pre Pick',
    pickedstock: 'Picked',
    pickinglist: 'Pick History',
    shippedstock: 'Shipping List',
    received: 'Received',
    pod: 'Proof Of Delivery',
    order_complete: 'Fulfilled',
    view_order: {
      order_code: 'Order Code',
      order_status: 'Order Status',
      goods_code: 'Goods Code',
      goods_desc: 'Goods Description',
      goods_qty: 'Order Quantity',
      customer: 'Customer'
    },
    view_dn: {
      dn_code: 'DN Code',
      dn_status: 'DN Status',
      goods_qty: 'Order QTY',
      intransit_qty: 'Shipping QTY',
      delivery_actual_qty: 'Delivery Actual QTY',
      delivery_shortage_qty: 'Delivery Shortage QTY',
      delivery_more_qty: 'Delivery More QTY',
      delivery_damage_qty: 'Delivery Damage QTY',
      total_weight: 'Total Weight(Kilogram)',
      total_volume: 'Total Volume(Cubic Metres)',
      customer: 'Customer'
    }
  },
  staff: {
    staff: 'Staff',
    check_code: 'Check Code',
    view_staff: {
      staff_name: 'Staff Name',
      staff_type: 'Staff Type',
      error1: 'Please Enter The Staff Name',
      error2: 'Please Enter The Staff Type',
      lock: 'lock',
      unlock: 'unlock'
    }
  },
  stock: {
    stocklist: 'Stock List',
    stockbinlist: 'Bin List',
    emptybin: 'Empty Bin',
    occupiedbin: 'Occupied Bin',
    view_stocklist: {
      goods_code: 'Goods Code',
      goods_desc: 'Goods Desc',
      goods_name: 'Goods Name',
      goods_qty: 'Total Qty',
      onhand_stock: 'On hand',
      can_order_stock: 'Can Order',
      ordered_stock: 'To be fulfilled',
      inspect_stock: 'Inspect',
      hold_stock: 'Holding',
      damage_stock: 'Damage',
      asn_stock: 'ASN Stock',
      dn_stock: 'DN Stock',
      pre_load_stock: 'Pre Load',
      pre_sort_stock: 'Pre Sort',
      sorted_stock: 'Sorted Stock',
      pick_stock: 'Pick Stock',
      picked_stock: 'Picked Stock',
      back_order_stock: 'Back Order',
      on_hand_inventory: 'On-Hand Inventory',
      history_inventory: 'History Inventory',
      physical_inventory: 'Physical Inventory',
      difference: ' Difference',
      cyclecount: 'Cycle Count',
      recyclecount: 'Recycle',
      downloadcyclecount: 'Counting table',
      cyclecountresult: 'Confirm result',
      cyclecounttip: 'Generate A Dynamic Cycle Count Table',
      recyclecounttip: 'Generate A Recycle Count Table',
      downloadcyclecounttip: 'Download Cycle Count Table',
      cyclecountresulttip: 'Confirm The Cycle Count Result',
      daychoice: 'Date Selection',
      daychoicetip: 'Select The Cycle Count Table Corresponding To The Date',
      error1: 'Count Quantity Must Be Greater Than 0',
      dateerror: 'Incorrect Date Selected'
    }
  },
  warehouse: {
    warehouse: 'Warehouse',
    view_layout: 'View Warehouse Layout',
    layout_config: 'Layout Configuration',
    binset: 'Bin Set',
    binsize: 'Bin Size',
    property: 'Bin Property',
    printbin: 'Print Bin Label',
    view_warehouseset: {
      error1: 'Please Enter the Warehouse Name',
      error2: 'Please Enter the Warehouse City',
      error3: 'Please Enter the Warehouse Address',
      error4: 'Please Enter the Warehouse Contact',
      error5: 'Please Enter the Warehouse Manager',
      error6: 'Please Enter valid Number of Racks',
      error7: 'Please Enter valid Number of Floors',
      error8: 'Please Enter valid Number of Bins per Floor'
    },
    view_warehouse: {
      warehouse_name: 'Warehouse Name',
      warehouse_city: 'Warehouse City',
      warehouse_address: 'Warehouse Address',
      warehouse_contact: 'Warehouse Contact',
      warehouse_manager: 'Warehouse Manager',
      racks_count: 'Racks Count',
      floors_count: 'Floors Count',
      bins_per_floor: 'Bins (per Floor)',
      square_measure: 'Usable Area',
      city_search: 'City Search',
      publish_warehouse: 'Publish Warehouse',
      Nopublish_warehouse: 'Recall Warehouse'
    },
    view_binset: {
      bin_name: 'Bin Name',
      bin_description: 'Bin Description',
      bin_size: 'Bin Size',
      bin_property: 'Bin Property',
      empty_label: 'Empty Label',
      error1: 'Please Enter the Bin Name',
      error2: 'Please Enter the Bin Size',
      error3: 'Please Enter the Bin Property',
      error4: 'Please Enter the Bin Description',
    },
    view_binsize: {
      bin_size: 'Bin Size',
      bin_size_w: 'Bin Size Wide(Metre)',
      bin_size_d: 'Bin Size Depth(Metre)',
      bin_size_h: 'Bin Size Height(Metre)',
      error1: 'Please Enter the Bin_size',
      error2: 'Bin Size width must greater than 0',
      error3: 'Bin Size depth must greater than 0',
      error4: 'Bin Size height must greater than 0'
    },
    view_property: {
      bin_property: 'Bin Property'
    }
  },
  integrationhub: {
    salesforce: 'Salesforce',
    ftp: 'FTP Server',
  },
  customconfig: {
    customconfig: 'Custom Configuration',
    asnconfig: 'ASN Configuration',
    orderconfig: 'Order Configuration',
    uploadnewasnfile: 'After New ASN',
    uploadconfirmasnfile: 'After Confirm ASN',
    uploadloadingfile: 'After Loading',
    uploadsortedfile: 'After Sorting',
    uploadputawayfile: 'After Put Away',
    uploadconfirmorderfile: 'After Confirm Order',
    uploadreleaseorderfile: 'After Release Order',
  },
  scan: {
    scan: 'Scan',
    scan_asn: 'ASN query',
    scan_dn: 'DN query',
    scan_sorting: 'Sorting',
    scan_uptobin: 'Up To Bin',
    scan_picking: 'Picking',
    scan_shipping: 'Shipping',
    scan_movetobin: 'Move To Bin',
    scan_inventory: 'Inventory',
    scan_goodsquery: 'Goods query',
    scan_locationquery: 'Location query',
    scan_goods_code: 'Goods Code',
    scan_bin_name: 'Bin Name',
    scan_goods_label: 'Goods label',
    scan_goods_label_error: 'The Goods Label Does Not Exist',
    view_binmove: {
      old_bin_name: 'Original Bin name',
      new_bin_name: 'New Bin Name',
      qty: 'Number of Goods Moved',
      qty_error: 'The Quantity To Be Moved Cannot Be Greater Than The Existing quantity'
    },
    view_upToBin: {
      goods_actual_qty: 'Actual Arrival Quantity',
      scan_qty: 'Scanned Qty',
      scan_qty_error: 'The Scan Quantity Cannot Be Greater Than The Arrival Quantity'
    },
    view_picking: {
      order_qty: 'Order Quantity',
      picking_qty: 'Pick quantity',
      picking_qty_error: 'The Picking Quantity Cannot Be Greater Than The Order Quantity'
    },
    view_shipping: {
      shipping_code: 'Shipment Number',
      driver_info: 'Driver Information',
      license_plate_number: 'License Plate Number',
      name: 'Name',
      contact_info: 'Contact Information'
    }
  },
  handcount: {
    handcount: 'Single Count',
    handcountrecorder: 'Single Count Recorder',
    update_time: 'Count Time'
  },
  notice: {
    valerror: 'Please Enter The Correct Value',
    unknow_error: 'Unknown Error',
    network_error: 'Network Exception',
    cyclecounterror: 'No data',
    userererror: 'Username already Exists',
    capitalerror: 'Fixed Asset Name Already Exists',
    valuenullerror: 'Please Fill In The Complete Data',
    loginerror: 'Please Login First',
    detail: 'Detail',
    goodserror: {
      goods_listerror: 'The product code already exists',
      goods_uniterror: 'Goods unit already exists',
      goods_classerror: 'Goods category already exists',
      goods_colorerror: 'Goods color already exists',
      goods_branderror: 'The product brand already exists',
      goods_shapeerror: 'Goods shape already exists',
      goods_specserror: 'Goods specification already exists',
      goods_originerror: 'The origin of goods already exists'
    },
    baseinfoerror: {
      companyerror: 'Company name already exists',
      customererror: 'Customer name already exists',
      suppliererror: 'Supplier name already exists'
    },
    warehouseerror: {
      binseterror: 'The bin name already exists',
      binsizeerror: 'bin size already exists'
    },
    mobile_userlogin: {
      notice1: 'Please enter your administrator name',
      notice2: 'Please enter your administrator password',
      notice3: 'Please enter your staff name',
      notice4: 'Please enter your staff verification code',
      notice5: 'Please enter your Openid in the settings server',
      notice6: 'Successful login',
      notice7: 'User or password mismatch',
      notice8: 'Employee or inspection code mismatch',
      notice9: 'Please login first'
    },
    mobile_scan: {
      notice1: 'QR code does not exist',
      notice2: 'Code does not exist',
      notice3: 'Server Error',
      notice4: 'Only mobile can scan'
    },
    mobile_asn: {
      notice1: 'ASN List',
      notice2: 'You can scan the QR code of the arrival notice, or click the arrival notice to view the details of the arrival notice and operate',
      notice3: 'Supplier:',
      notice4: 'Total amount:',
      notice5: 'Status:',
      notice6: 'Details of the arrival notice',
      notice7: 'You need to scan the arrival notice to get the details of the arrival notice. You can scan the cargo code or click on the goods you want to put on the shelves to complete the operation of the goods on the shelves',
      notice8: 'Details',
      notice9: 'Total amount:',
      notice10: 'Number to be listed:',
      notice11: 'The number of listings must be greater than 0',
      notice12: 'Successful listing',
      notice13: 'Please enter the location code'
    },
    mobile_dn: {
      notice1: 'DN List',
      notice2: 'You can scan the QR code of the DN Order, or click on the DN order to view the details of the DN and perform operations',
      notice3: 'Customer:',
      notice4: 'Total amount:',
      notice5: 'Status:',
      notice6: 'DN details',
      notice7: 'The details of the DN are all invoices. Scan the DN Number to view the details of the specific DN',
      notice8: 'Details',
      notice9: 'Total amount:',
      notice10: 'Invoice quantity:',
      notice11: 'All the details of the picking list are here, you can also scan the specific goods, or the DN to get the picking list to be operated',
      notice12: 'Please enter the specific picking quantity',
      notice13: 'Successful Picking',
      notice14: 'Bin name:',
      notice15: 'Quantity to be picked:'
    },
    mobile_goodsstock: {
      notice1: 'Stock List',
      notice2: 'Here you can see all the inventory information, click to view the inventory information directly',
      notice3: 'On-Hand Stock:',
      notice4: 'Can Ordered Stock:'
    },
    mobile_binstock: {
      notice1: 'Bin Stock List',
      notice2: 'Here you can see the inventory information of all the warehouse locations, click to directly access the inventory of the warehouse location, carry out the warehouse transfer operation, or scan the goods to check the storage status of all the goods',
      notice3: 'Bin Name:',
      notice4: 'Storage quantity:',
      notice5: 'Please enter the Bin Name',
      notice6: 'Repository moved successfully'
    },
    mobile_emptybin: {
      notice1: 'Empty Bin list',
      notice2: 'Here you can see all the empty location',
      notice3: 'Stock Bin Property:'
    },
    equipment: {
      notice1: 'Equipment Support List',
      notice2: 'All device brands and systems supported by the APP are listed here'
    },
    handcount: {
      notice1: 'Details',
      notice2: 'Manual Count',
      notice3: 'On-Hand Stock',
      notice4: 'Count Quantity',
      notice5: 'Confirm Result',
      notice6: 'Confirm The Count Result',
      notice7: 'Successful Confirmed Count Result',
      notice8: 'Here shows the details of the goods that need to be counted'
    },
    400: 'Bad request (400)',
    401: 'Authorization not obtained (401)',
    403: 'Access denied (403)',
    404: 'Resource does not exist (404)',
    405: 'The function is disabled (405)',
    408: 'Request timed out (408)',
    409: 'Data conflict (409)',
    410: 'Data has been deleted (410)',
    500: 'Server error (500)',
    501: 'Service not implemented (501)',
    502: 'Network error (502)',
    503: 'Service unavailable (503)',
    504: 'Network timeout (504)',
    505: 'HTTP version is not supported (505)'
  }
}
